import React from "react"
import { ApolloProvider } from "react-apollo"
import { client } from "./src/services/apollo"

import { AppProvider } from "./src/context/AppContext"
import "./static/styles/global.scss"

export const wrapRootElement = ({ element }) => (
  <AppProvider>
    <ApolloProvider client={client}>{element}</ApolloProvider>
  </AppProvider>
)

export const onServiceWorkerUpdateReady = () => {
  window.location.reload()
}
